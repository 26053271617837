import Social from './Social';
import { Container } from 'react-bootstrap';
import { A } from "hookrouter";
import './Footer.css';
import Button from './Button';

const Footer = () => {

  const onClickHandler = (event) => {
    window.location.href = '/contacto';
  }

  return <footer>
    <Container>
      <div className='footer-content'>
        <div className='footer-links footer-item'>
          <h3 style={{ textAlign: 'center' }}>OECC</h3>
          <ul className='with-links'>
            <li><A href='/institucional'>Institucional</A></li>
            <li><a href='#'>Áreas Temáticas</a></li>
            <li><A href='/comunicacion'>Comunicación</A></li>
            <li><A href='/visualizaciones'>Visualizaciones</A></li>
            <li><a href='#'>Base de datos</a></li>
          </ul>
        </div>

        <div className='footer-links footer-item' style={{ paddingTop: '35px' }}>
          <ul className='info'>
            <li>Observatorio Económico de la Ciudad de Corrientes</li>
            <li>25 de Mayo 1132</li>
            <li>Corrientes, Argentina</li>
          </ul>
        </div>

        <div className='footer-item' style={{ textAlign: 'center' }}>
          <Button
            onclick={onClickHandler}
            type='button'
            >Contacto
          </Button>
          <div style={{ maxWidth: '160px', margin: '50px auto 0 auto' }}>
            <Social />
          </div>
        </div>
      </div>
    </Container>
  </footer>
};

export default Footer;