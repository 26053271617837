import React, { useEffect, useState } from 'react';
import './Home.css';
import * as constants from '../../constants';
import Indicadores from '../indicadores/Indicadores';
import { Row, Col } from 'react-bootstrap';
import { LastPublications } from '../LastPublications';
import { VisualizationsHome } from '../VisualizationsHome';
import NewsCard from '../cards/NewsCard';
import Comunicacion from '../comunicacion/Comunicacion';



const url = `${constants.API_URL}/observatorio-noticias?range=3`;

const renderedCard = (noticia, index) => {
  return (
    <NewsCard
      key={index}
      id={noticia.id}
      title={noticia.title}
      image={noticia.image.styles.medium}
      summary={noticia.contenido}
    />
  )
};

function Home() {

  const [noticias, setNoticias] = useState();
  const fetchApi = async () => {
    const response = await fetch(url);
    const responseJSON = await response.json();
    setNoticias(responseJSON.data);
  }

  useEffect(() => {
    fetchApi()
  }, []);

  return (
      <section className="home">
        <Indicadores />
        <Row>
          <Col lg={8}>
            <div className="home-block novedades">
              <h3 className="home-title">Novedades</h3>
              <div style={{minHeight:'420px'}}>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                  {!noticias ? 'Cargando' :
                    noticias.map(renderedCard)
                  }
                </div>
              </div>
            </div>

            <div className="home-block publicaciones">
              <h3 className="home-title">Últimas publicaciones</h3>
              <div className='with-bg'>
                <LastPublications />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="home-block calendario">
              <h3 className="home-title">Calendario</h3>
              <div className='with-bg'>
                <Comunicacion />
              </div>
            </div>

            <div className="home-block visualizaciones">
              <h3 className="home-title">Visualizaciones</h3>
              <div className='with-bg'>
                <VisualizationsHome />
              </div>
            </div>
          </Col>
        </Row>
      </section>
  );
}

export default Home;
