import './Button.css';

const Button = (props) => {
  return <button className='button'
    onClick={props.onclick}
    type={props.type}
    style={props.style}>
      {props.children}
  </button>

};

export default Button;