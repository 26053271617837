import facebook from '../../assets/social/facebook.png';
import github from '../../assets/social/github.png';
import instagram from '../../assets/social/instagram.png';
import twitter from '../../assets/social/twitter.png';
import './Social.css';

const Social = () => {
  return <ul className="social-icons">
  <li>
    <a href='https://www.facebook.com/corrientesmuni/' target='_blank'><img src={facebook} /></a>
  </li>
  <li>
    <a href='#' target='_blank'><img src={github} /></a>
  </li>
  <li>
    <a href='https://www.instagram.com/municorrientes/' target='_blank'><img src={instagram} /></a>
  </li>
  <li>
    <a href='https://twitter.com/municorrientes' target='_blank'><img src={twitter} /></a>
  </li>
</ul>
};

export default Social;