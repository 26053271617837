import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './LastPublications.css';
import { A } from 'hookrouter';
import * as constants from '../constants';
import slugify from 'react-slugify';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export const LastPublications = (props) => {
  const url = `${constants.API_URL}/observatorio-informes`;

  const [informes, setInformes] = useState();

  const fetchApi = async () => {
    const response = await fetch(url);
    const responseJSON = await response.json();
    console.log('los informes', responseJSON);
    setInformes(responseJSON.data);
  }

  useEffect(() => {
    fetchApi()
  }, []);

  const renderedInformes = (informe, index) => {
    let default_image = `${constants.BASE_URL}/images/img-informe.png`;

    return (
      <div key={index}>
        <A href={`/areas-tematicas/${informe.tematica.id}/${slugify(informe.tematica.label)}`}>
          <Image src={informe.image ? informe.image.styles.medium : default_image} fluid />
        </A>
      </div>
    )
  }
  return (
    <>
      <Carousel
        // afterChange={function(previousSlide,_ref){var currentSlide=_ref.currentSlide;_ref.onMove;return alert("previous slide is "+previousSlide+" currentSlide is "+currentSlide)}}
        swipeable={true}
        draggable={false}
        // showDots={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        // infinite={true}
        infinite={false}
        // autoPlay={props.deviceType !== "mobile" ? true : false}
        autoPlay={false}
        // autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-15-px"
        focusOnSelect={true}
      >
        { !informes ? <div></div> : 
          informes.map(renderedInformes)
        }
      </Carousel>
    </>
  );
}
