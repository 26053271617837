import React, { useState, useEffect, Fragment } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { A } from 'hookrouter';
import Comunicacion from '../comunicacion/Comunicacion';
import * as constants from '../../constants';
import './Comunication.css';

export const Comunication = () => {

  const url = `${constants.API_URL}/observatorio-eventos`;
  const monsths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const today = new Date(Date.now());
  const currentDay = `${today.getDate()} de ${monsths[today.getMonth()]}`;
  const currentMonth =  `${monsths[today.getMonth()]} ${today.getFullYear()}`;

  const [eventos, setEventos] = useState([]);
  const [eventosDia, setEventosDia] = useState([]);
  const [displayDelMes, setDisplayDelMes] = useState(false);
  const [displayDelDia, setDisplayDelDia] = useState(true);
  const [titleToShow, setTitleToShow] = useState(currentDay);


  const getEventosDia = (eventos) => {
    const date = new Date(Date.now());
    const currentDate =   date.toISOString().split('T')[0]
    const evto = eventos.filter((evento) => {
      const soloFecha = evento.fecha_evento.split(' ')[0];
      if (currentDate === soloFecha) {
        return evento;
      }
    });

    return evto;
  };

  const handleDisplay = (option) => {
    const titleDia = document.getElementById('dia');
    const titleMes = document.getElementById('mes');

    if (option === 'mes') {
      titleDia.classList.remove('event-list-header-active');
      titleDia.classList.add('event-list-header-inactive');

      titleMes.classList.remove('event-list-header-inactive');
      titleMes.classList.add('event-list-header-active');

      setDisplayDelDia(false);
      setDisplayDelMes(true);
      setTitleToShow(currentMonth);

    } else {
      titleDia.classList.remove('event-list-header-inactive');
      titleDia.classList.add('event-list-header-active');

      titleMes.classList.remove('event-list-header-active');
      titleMes.classList.add('event-list-header-inactive');
      setDisplayDelDia(true);
      setDisplayDelMes(false);
      setTitleToShow(currentDay);

    }
  };

  const fetchEventos = async () => {
    try {
      const response = await fetch(url);
      const responseJSON = await response.json();
      setEventos(responseJSON.data);
      const eventosDelDia = getEventosDia(responseJSON.data);
      setEventosDia(eventosDelDia);

    } catch (err) {
      setEventos({
        title: 'No encontrado',
      });
    }
  };

  const renderEvento = (evento, i) => {
    const fecha = new Date(evento.fecha_evento);
    const dayOfMonth = fecha.getDate();
    const dayEvento ='hoy es:';
    return <div className='eventos-list' key={evento.id}>
      <div className='day-evento'>{displayDelMes && dayOfMonth === today.getDate() && dayEvento}</div>
      <div className='title'>{evento.title}</div>
      <div className='time'>{ fecha.getHours() }:{ fecha.getMinutes() }</div>
      <div className='link' style={{textAlign:'right'}}><A href='/comunicacion'> &#62; </A></div>
    </div>
  };

  // const renderFechaEvento = (evento, i) => {
  //   const fecha = new Date(evento.fecha_evento);
  //   return <div className='time'>{ days[fecha.getDay()] }</div>
  // };

  useEffect(() => {
    fetchEventos()
  }, []);

  return (
    <section className='page page-comunicacion'>
      <h1>Comunicación</h1>
      <Row>
        <Col lg={3} sm={3} md={3}>
          <div className='with-bg'>
            <Comunicacion />
          </div>
        </Col>


        <Col lg={9} sm={9} md={9}>
          <Row>

          <Col lg={3}>
          <ul className='comunication-header'>
            <li id='dia' className='dia event-list-header-active' onClick={() => {handleDisplay('dia')}}>Día</li>
            <li id='mes' className='mes event-list-header-inactive' onClick={() => {handleDisplay('mes')}}>Mes</li>
          </ul>
          <div className='title-to-show text-center'>
            {titleToShow}
          </div>
        </Col>
        <Col lg={9}>
          <Form>
            <Form.Group controlId="formGridState">
              <Form.Control as="select" className='comunication-search'>
                <option></option>
              </Form.Control>
            </Form.Group>
          </Form>

          <div className='comunication-list'>
            <div className='eventos-header'>
              <div className='title-header'>PUBLICACIÓN</div>
              <div className='time'>HORA</div>
              <div className='link'>VER</div>
            </div>
          </div>
          </Col>
          </Row>

          <Row>
            
            <Col>
              <div className='comunication-list'>
                {eventosDia && displayDelDia && eventosDia.map(renderEvento)}
                {eventos && displayDelMes && eventos.map(renderEvento)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
};