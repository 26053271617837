import React, { useEffect, useState } from 'react';
// import '../App.css';
import './Indicadores.css';
import * as constants from '../../constants';
import IndicadoresItem from './IndicadorItem';

const url = `${constants.API_URL}/observatorio-indicadores?sort=orden`;
const animationDuration = constants.STAT_ANIMATION_DURATION;

function Indicadores() {

  const [indicadores, setIndicadores] = useState();
  const [indicadoresReady, setIndicadoresReady] = useState(false);

  const renderedIndicador = (indicador, index) => {
    return <li>
        <IndicadoresItem
          title={indicador.title}
          animationDuration={animationDuration}
          value={indicador.valor}
          icono={indicador.icono}
          description={indicador?.description?.replace(/(<([^>]+)>)/gi, "")}
          format={indicador.formato}
        />
      </li>
  };

  const getIndicadores = async () => {
    try {
      const response = await fetch(url);
      const responseJSON = await response.json();
      setIndicadores(responseJSON.data);
      setIndicadoresReady(true);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getIndicadores()
  }, []);

  return (
    <div className='resumen-container' style={{ backgroundImage: 'url(' + constants.BASE_URL + '/images/stat-fd.png)', backgroundSize: 'cover' }}>
      <div className="statictics">
      {indicadoresReady ? <ul className='datos'>{indicadores.map(renderedIndicador)}</ul> : 'cargando...'}
      </div>
    </div>
  );
}

export default Indicadores;
