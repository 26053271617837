import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as constants from '../../constants';
import './Visualizaciones.css';

export const Visualizaciones = () => {
  const url = `${constants.API_URL}/observatorio-visualizaciones`;
  const [visu, setVisu] = useState();

  const fetchVisu = async () => {
    try {
      const result = await fetch(url);
      const resultJSON = await result.json();
      setVisu(resultJSON.data);
    } catch(error) {
      throw error;
    }
  };

  const visuClickHandler = (v) => {
    const contentHolder = document.getElementById('visu-content')
    contentHolder.innerHTML = v.description;
  }

  const renderedVisu= (v, i) => {
    return (
      <li onClick={() => visuClickHandler(v)} key={i}>{v.title}</li>
    )
  };

  const content = !visu ? '': <div id='visu-content' dangerouslySetInnerHTML={{ __html:visu[0].description}}></div>;

  useEffect(() => {
    fetchVisu();
  }, []);
  return <section className='page'>
    <h1 style={{marginTop: '30px'}}>Visualizaciones</h1>
    <Row>
      <Col lg={2}>
        {!visu ? 'cargando...' : <ul className='visu-list'>{visu.map(renderedVisu)}</ul>}
      </Col>
      <Col lg={10}>
        {content}
      </Col>
    </Row>
  </section>
};

export default Visualizaciones;