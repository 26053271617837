import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Button from '../general/Button';
import './Contact.css';

export const Contact = (props) => {
  const onClickHandler = (event) => {
    window.location.href = '/home';
  }
  return (
    <section className="page contact-page">
      <h1>Contacto</h1>
      <p className='contacto-text'>
      ¡Hola! Desde el Observatorio Económico te invitamos a esta sección para que nos dejes un comentario sobre las temáticas que te gustaría que investiguemos, preguntas u opiniones acerca nuestros proyectos, gracias :)
      </p>
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formGridAddress1">
              <Form.Label>Nombre y Apellido</Form.Label>
              <Form.Control
                placeholder=""
                type="text"
                required />
            </Form.Group>

            <Form.Group controlId="formGridAddress2">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control placeholder="" />
            </Form.Group>

            <Form.Group controlId="formGridCity">
              <Form.Label>Ocupación</Form.Label>
              <Form.Control />
            </Form.Group>

            <Form.Group controlId="formGridState">
              <Form.Label>Género</Form.Label>
              <Form.Control as="select">
                <option></option>
                <option>Masculino</option>
                <option>Femenino</option>
                <option>Otro</option>
                <option>Prefiero no decirlo</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formGridZip">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control type="email" />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={5} />
            </Form.Group>

            <Button style={{float: 'right'}}
              onclick={onClickHandler}
              type="submit">
              Enviar
          </Button>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </section>
  );
}
