import { Fragment } from 'react';
import AnimatedNumber from 'react-animated-number';
import statBus from '../../assets/indicators/stat-bus.png';
import statGender from '../../assets/indicators/stat-gender.png';
import statJob from '../../assets/indicators/stat-job.png';
import statPeople from '../../assets/indicators/stat-people.png';
import statStatistic from '../../assets/indicators/stat-statistic.png';
import './IndicadoresItem.css';

const IndicadoresItem = props => {

  const icons = {
    statBus,
    statGender,
    statJob,
    statPeople,
    statStatistic,
  }

  const format = props.format;
  let item;
  if (format === 'porcentaje') {
    item = <AnimatedNumber
      component='div'
      value={parseFloat(props.value)}
      style={
        {
          fontSize: '20px',
          color: 'white',
          fontWeight: 'bold',
          textAlign: 'center',
        }
      }
      duration={props.animationDuration}
      formatValue={n => `${n.toFixed(2).replace(".",",")} %`}
      frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
    />
  } else {
    item = <AnimatedNumber
      component='div'
      value={parseInt(props.value)}
      style={
        {
          fontSize: '20px',
          color: 'white',
          fontWeight: '900',
          textAlign: 'center',
        }
      }
      duration={props.animationDuration}

      formatValue={n => n.toFixed(0)}
      frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
    />
  }

  return <Fragment>
    <div className='image-stat'>
      <img src={icons[props.icono]} />
    </div>
    {item}
    <div className="title-stat">
      {props.title}
    </div>
    <div className='period-stat'>
      {props.description}
    </div>
  </Fragment>
};

export default IndicadoresItem;