export const ActividadEconomica = [
    {
        id: 706,
        title: 'Sector Agricultura y Ganadería',
        path: '/areas-tematicas/706/sector-agricultura-y-ganaderia',
        cName: 'dropdown-link'
    },
    {
        id: 707,
        title: 'Sector Comercio',
        path: '/areas-tematicas/707/sector-comercio',
        cName: 'dropdown-link'
    },
    {
        id: 708,
        title: 'Sector Construcción',
        path: '/areas-tematicas/708/sector-construccion',
        cName: 'dropdown-link'
    },
    {
        id: 709,
        title: 'Sector de Servicios de Alojamiento y Comida',
        path: '/areas-tematicas/709/sector-de-servicios-de-alojamiento-y-comida',
        cName: 'dropdown-link'
    },
    {
        id: 710,
        title: 'Sector Energético',
        path: '/areas-tematicas/710/sector-energetico',
        cName: 'dropdown-link'
    },
    {
        id: 711,
        title: 'Sector Financiero y de Seguros',
        path: '/areas-tematicas/711/sector-financiero-y-de-seguros',
        cName: 'dropdown-link'
    },
    {
        id: 712,
        title: 'Sector Industrial',
        path: '/areas-tematicas/712/sector-industrial',
        cName: 'dropdown-link'
    },
    {
        id: 713,
        title: 'Sector Transporte y Almacenamiento',
        path: '/areas-tematicas/713/sector-transporte-y-almacenamiento',
        cName: 'dropdown-link'
    },
    {
        id: 714,
        title: 'Total de Empresas',
        path: '/areas-tematicas/714/total-de-empresas',
        cName: 'dropdown-link'
    }
];

export const SocioDemografico = [
    {
        id: 701,
        title: 'Mercado Laboral',
        path: '/areas-tematicas/701/mercado-laboral',
        cName: 'dropdown-link'
    },
    {
        id: 702,
        title: 'Necesidades Básicas Insatisfechas',
        path: '/areas-tematicas/702/necesidades-basicas-insatisfechas',
        cName: 'dropdown-link'
    },
    {
        id: 703,
        title: 'Acceso y uso de la Tecnología',
        path: '/areas-tematicas/703/acceso-y-uso-de-la-tecnologia',
        cName: 'dropdown-link'
    },
    {
        id: 704,
        title: 'Migración',
        path: '/areas-tematicas/704/migracion',
        cName: 'dropdown-link'
    }
];
