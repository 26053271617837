import React, { useEffect, useState } from 'react';
import * as constants from './../../constants';
import { A } from 'hookrouter';
// import { useParams } from "react-router-dom";
import '../../App.css';
import './Tematicas.css';

export const Tematicas = (props) => {

  const { idarea, area } = props;

  const url = `${constants.API_URL}/observatorio-informes?filter[tematica]=${idarea}`;
  const [informes, setInformes] = useState(null);

  const renderedCard = (informe, index) => {
    let output;
    if (index === 0) {
      output = <div key={index} className="featured-informe">

        <h3 className="subtitle-informes">Último Informe</h3>

        <div style={{ display: 'flex' }}>
          <div className="block-1">
            <div>{new Date(informe.fecha * 1000).toLocaleDateString('es-AR')}</div>
            <h4>{informe.title}</h4>
            <div className='trimestres'>
              {informe.trimestre_inicio} - {informe.trimestre_fin}
            </div>
          </div>
          <div className="block-2">
            {informe?.description?.substring(0, 250)}
          </div>
          <div className="block-3 text-center">
            <div>
              <img className="img-fluid" src={`${constants.BASE_URL}/images/img-informe.png`} />
            </div>
            <div>
              <A target='_blank' href={informe.archivo.self}>Ver informe</A>
            </div>
          </div>
        </div>
      </div>
    } else {
      output = <div key={index}>
        {index === 1 && <h3 style={{ marginTop: '30px' }} className="subtitle-informes">Informes Anteriores</h3>}

        <div className="list-informe">
          <div className="list-informe-fecha">
            {new Date(informe.fecha * 1000).toLocaleDateString('es-AR')}
          </div>

          <div className="list-informe-periodo">
            {informe.trimestre_inicio} - {informe.trimestre_fin}
          </div>

          <div className="list-informe-title">
            <A target='_blank' className='informe-title' href={informe.archivo.self}>{informe.title}</A>
          </div>
        </div>
      </div>
    }
    return (
      output
    )
  }

  const fetchApi = async () => {
    try {
      const response = await fetch(url);
      const responseJSON = await response.json();
      setInformes(responseJSON.data);
    } catch(err) {
       setInformes({
         title: 'Informe no encontrado',
         tematica: {
           label: 'Informe no encontrado',
         }
       });
    }
    
  }

  useEffect(() => {
    fetchApi()
  }, [informes]);

  return (
    <section className="page informes mercado-laboral">
      <h1 className="subtitle-informes">{informes && informes[0]?.tematica?.label}</h1>

      { !informes ? 'Cargando' :
        <div className="informes-mercado-laboral">
          <div className='tematica-texto' dangerouslySetInnerHTML={{ __html: informes[0]?.tematica?.description }}></div>
          {informes.map(renderedCard)}

          <div style={{marginTop: '30px'}}>
            <h3 className='subtitle-informes'>Serie de datos históricos</h3>
            <p>Podes encontrar la serie de datos históricos de nuestros informes en el Portal de Datos Abiertos de la Municipalidad de Corrientes</p>
            <p>Link: <a target='_blank' href={informes[0]?.tematica?.serie_de_datos} target='_blank'>{informes[0]?.tematica?.serie_de_datos}</a></p>
          </div>
        </div>
      }
    </section>);
}
