import React, { useEffect, useState } from 'react';
import * as constants from '../../constants';
// import { useParams } from "react-router-dom";
import '../../App.css';

export const PublicationDetails = (props) => {
  const { nid } = props;
  const url = `${constants.API_URL}/observatorio-informes/${nid}`;
  const [informe, setInformes] = useState();

  const fetchApi = async () => {
    const response = await fetch(url);
    const responseJSON = await response.json();
    setInformes(responseJSON.data[0]);
  }

  useEffect(() => {
    fetchApi()
  }, []);

  return (
    <section className="informe">
    { !informe ? 'Cargando' : 
      <div className="informe-details">
      <h2>{informe.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: informe?.description }}></div>
      <img src={informe?.image?.styles?.large} />
    </div>
    }
  </section>);
}
