import './App.css';
import { useRoutes, A } from "hookrouter";
import Institucional from "./components/pages/Institucional";
import Home from './components/pages/Home';
import { Contact } from './components/pages/Contact';
import { Navigation } from './components/Navigation';
import { Comunication } from './components/pages/Comunication';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NoticiaDetails } from './components/pages/NoticiaDetails';
import { PublicationDetails } from './components/pages/PublicationDetails';
import { Tematicas } from './components/pages/Tematicas';
import Visualizaciones from './components/pages/Visualizaciones';

import Menu from './components/Menu';
import Header from './components/general/Header';
import Footer from './components/general/Footer';

const routes = {
  '/': () => <Home />,
  '/institucional': () => <Institucional />,
  '/comunicacion': () => <Comunication />,
  '/contacto': () => <Contact />,
  '/noticia/:id': ({id}) => <NoticiaDetails nid={id} />,
  '/informe/:id': ({id}) => <PublicationDetails nid={id} />,
  '/areas-tematicas/:idarea/:area': ({idarea, area}) => <Tematicas idarea={idarea} area={area} />,
  '/visualizaciones': () => <Visualizaciones />,
};

function App() {

  const routeResult = useRoutes(routes);

  return (
    <div className="App">
      <Container fluid="md">
        {/* <Header /> */}
        <Menu />
        {/* <Navigation/> */}
        {routeResult}
      </Container>
      <Footer />
    </div>
  );
}

export default App;
