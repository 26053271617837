import { A } from 'hookrouter';
import './NewsCard.css';

const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

const NewsCard = props => {

  const {id, title, image, summary } = props;

  return <div className='news-card'>
    <div className='news-image'>
       <img className='fluid with-border' src={image} />
    </div>
    
    <div className='title'>
      <A className='noticia-title' title={title} style={{fontWeight: '600'}} href={`/noticia/${id}`}>{truncate(title, 50)}</A>
    </div>

    <div className='summary truncate-overflow'>
      {summary.replace(/(<([^>]+)>)/gi, "")}
    </div>

    <div className='footer text-muted'>
      <A style={{color: '#1680ab', fontWeight: '700'}} href={`/noticia/${id}`}>Ver más</A>
    </div>
    
  </div>
};

export default NewsCard;