import React, { useEffect, useState } from 'react';
import * as constants from '../../constants';
// import { useParams } from "react-router-dom";
import '../../App.css';

export const NoticiaDetails = (props) => {
  const { nid } = props;
  const url = `${constants.API_URL}/observatorio-noticias/${nid}`;
  const [noticia, setNoticia] = useState();

  const fetchApi = async () => {
    const response = await fetch(url);
    const responseJSON = await response.json();
    setNoticia(responseJSON.data[0]);
  }

  useEffect(() => {
    fetchApi()
  }, []);

  return (
    <section className="page noticia">
    { !noticia ? 'Cargando' : 
      <div className="noticia-details">
      <h2>{noticia.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: noticia.contenido }}></div>
      <img src={noticia?.image?.styles?.large} />
    </div>
    }
  </section>);
}
