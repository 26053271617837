import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Comunicacion = props => {

  const onChangeHandler = nextDate => {
    const fecha = nextDate.toISOString();
    window.location.href = '/comunicacion';
  };

  return <Calendar
    onChange={onChangeHandler}
  />
};

export default Comunicacion;