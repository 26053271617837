import React, { useState, useEffect } from 'react';
import * as constants from './../../constants';
import axios from 'axios';

export default function Institucional() {

  const [institucional, setInstitucional] = useState();
  const [institucionalReady, setInstitucionalReady] = useState(false);

  const url = `${constants.API_URL}/nodes/${constants.INSTITUCIONAL_PAGE_ID}`;

  const getInstitucional = async () => {
    try {
      const response = await fetch(url);
      const responseJSON = await response.json();
      setInstitucional(responseJSON.data[0]);
      setInstitucionalReady(true);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getInstitucional();
  }, []);

  return (
    <section className="page institucional">
      <h1 className='institucional'>institucional</h1>

      {institucionalReady ?
        <div className="institucional-content" dangerouslySetInnerHTML={{ __html: institucional.body }}></div>:
        null
      }
      
    </section>
  );
}
