import React, { useEffect, useRef, useState } from 'react';
import './Menu.css';
import { Row, Col, Image } from 'react-bootstrap';
import { A } from 'hookrouter';
import * as constants from '../constants';
import { SocioDemografico, ActividadEconomica } from './MenuItems';
// import { faSearch } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Social from '../components/general/Social';
import logo from '../assets/logo.png';
import logoMuni from '../assets/logo-muni.png';

const Menu = (props) => {

  const menuATref = useRef();

  const [menuATStyle, setMenuATStyle] = useState('none');

  const toggleMenuHandler = (event) =>{
    if (menuATref.current.style.display === 'none') {
      setMenuATStyle('flex');
    } else {
      setMenuATStyle('none');
    }
  };


  useEffect(() => {
  }, [toggleMenuHandler]);
  return (
    <>
      <Row className="top">
        <Col sm><Image style={{maxWidth: '150px'}} src={logo} fluid /></Col>
        <Col sm><Image src={logoMuni} fluid /></Col>
        <Col>
          <div className='social-icons' style={{marginTop: '10px'}}>
            <Social />
          </div>
        </Col>
        <Col>
          <div className="input-container" style={{marginTop: '15px'}}>
            <FontAwesomeIcon className="icon" icon={faSearch} />
            <input className="buscar" type="text" placeholder="Buscar" />

          </div>
        </Col>
      </Row>
      <nav>
        <A href="#" className="mobile-menu-trigger"><FontAwesomeIcon className="icon" icon={faBars} /></A>
        <ul className="menu menu-bar">
          <li>
            <A className="menu-link menu-bar-link" href="/"><Image src={`${constants.BASE_URL}/images/home.png`} /></A>
          </li>

          <li>
            <A className="menu-link menu-bar-link" href="/institucional">
              institucional
                    </A>
          </li>

          <li>
            <A href="#" className="menu-link menu-bar-link" onClick={toggleMenuHandler} aria-haspopup="true">áreas temáticas</A>
            <ul className="mega-menu mega-menu--flat" ref={menuATref} style={{display: menuATStyle}}>
              <li style={{ borderRight: '1px solid #1891c2', padding: '15px' }}>
                <A href="/page" className="menu-link mega-menu-link mega-menu-header">socio demográfica</A>
                <ul className="menu menu-list">
                  {SocioDemografico.map((item, index) => {
                    return (
                      <li key={index} onClick={toggleMenuHandler}>
                        <A href={item.path}
                          className={item.cName}
                          to={item.path}
                        >
                          {item.title}
                        </A>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li style={{ borderRight: '1px solid #1891c2', padding: '15px', flexBasis: '35%' }}>
                <A href="/page" className="menu-link mega-menu-link mega-menu-header">actividad económica</A>
                <ul className="menu menu-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {ActividadEconomica.map((item, index) => {
                    return (
                      <li key={index} style={{ flexBasis: '50%' }} onClick={toggleMenuHandler}>
                        <A href={item.path}
                          className={item.cName}
                          to={item.path}
                        >
                          {item.title}
                        </A>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li style={{ padding: '15px' }}>
                <A onClick={toggleMenuHandler} href="/areas-tematicas/716/genero" className="menu-link mega-menu-link mega-menu-header">género</A>
              </li>
              <li className="mobile-menu-back-item">
                <A href="#" className="menu-link mobile-menu-back-link">Back</A>
              </li>
            </ul>
          </li>

          <li>
            <A href="/visualizaciones" className="menu-link menu-bar-link">visualizaciones</A>
          </li>

          <li>
            <A href="/comunicacion" className="menu-link menu-bar-link">comunicación</A>
          </li>

          <li>
            <A href="/contacto" className="menu-link menu-bar-link">contacto</A>
          </li>

          {/* <li className="mobile-menu-header">
                    <A href="/home" className="">
                        <span>Home</span>
                    </A>
                </li> */}
        </ul>
      </nav>
    </>
  );
}

export default Menu